import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"


const NotFoundPage = () => (
  <Layout>
    <SEO title="Chyba 404: Stránka nenalezena" />
    <h1>Chyba 404: Stránka nenalezena</h1>
    <p>Stránku, kterou se snažíte najít, zřejmě snědla kočka. </p>
    <Link to="/"><button>Přejít na hlavní stránku</button></Link>
  </Layout>
)

export default NotFoundPage
